<template>
  <div class="handle-container">
    <div class="bread">功能管理 / <span class="gray">所属企业</span></div>
    <div class="formCompany">
      <div class="formSubmit">
        <h1 class="title">所属企业</h1>
        <div class="formUpdate">
          <div class="img">
            <el-form :model="formData" :rules="ruleCompany" ref="createForm" label-width="150px" :label-position="labelPosition2">
              <el-row align="middle" :gutter="10">
                <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="4">
                  <el-form-item label="营业执照" prop="businessLicenseUrl">
                    <img-upload
                        ref="upload"
                        img-name="营业执照"
                        :value="formData.businessLicenseUrl"
                        :disable="true"
                        @input="getImg1"
                    >
                    </img-upload>
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="4">
                  <el-form-item label="授权委托书" prop="proxyStatementUrl">
                    <img-upload
                        ref="upload"
                        img-name="授权委托书"
                        :value="formData.proxyStatementUrl"
                        @input="getImg2"
                    >
                    </img-upload>
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="4">
                  <el-form-item label="道路运输许可证" prop="transportationLicenseUrl">
                    <img-upload
                        ref="upload"
                        img-name="道路运输许可证"
                        :value="formData.transportationLicenseUrl"
                        @input="getImg3"
                    >
                    </img-upload>
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="4">
                  <el-form-item label="出租车运营资格证" prop="taxiOperationLicenseUrl">
                    <img-upload
                        ref="upload"
                        img-name="出租车运营资格证"
                        :value="formData.taxiOperationLicenseUrl"
                        @input="getImg4"
                    >
                    </img-upload>
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="4">
                  <el-form-item label="公司LOGO" prop="logoUrl">
                    <img-upload
                        ref="upload"
                        img-name="公司LOGO"
                        :value="formData.logoUrl"
                        @input="getImg5"
                    >
                    </img-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row align="middle" :gutter="10">
                <el-form-item label="公司环境图" prop="enterprisePhoto">
                  <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="4">
                    <img-upload
                        ref="upload"
                        img-name="公司环境图"
                        :value="formData.enterprisePhoto[0]"
                        @input="getImg6"
                    >
                    </img-upload>
                  </el-col>
                  <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="4">
                    <img-upload
                        ref="upload"
                        img-name="公司环境图"
                        :value="formData.enterprisePhoto[1]"
                        @input="getImg7"
                    >
                    </img-upload>
                  </el-col>
                  <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="4">
                    <img-upload
                        ref="upload"
                        img-name="公司环境图"
                        :value="formData.enterprisePhoto[2]"
                        @input="getImg8"
                    >
                    </img-upload>
                  </el-col>
                </el-form-item>
              </el-row>
            </el-form>
          </div>

          <div class="words">
            <el-form :model="formData" :rules="ruleCompany" ref="createForm" label-width="150px" :label-position="labelPosition">
              <el-row align="middle" :gutter="10">
                <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="8">
                  <el-form-item label="企业全称" prop="enterpriseName">
                    <el-input v-model="formData.enterpriseName" type="text" placeholder="请输入企业全称" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="8">
                  <el-form-item label="所在地址" prop="enterpriseLocation">
                    <el-cascader
                        v-model="formData.enterpriseLocation"
                        :options="cityList"
                        placeholder="请选择所在地址"
                        :props="{ value: 'id',label:'text'}"
                        @change="handleChange">
                    </el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="8">
                  <el-form-item label="详细地址" prop="enterpriseAddress">
                    <el-input v-model="formData.enterpriseAddress" type="text" placeholder="请输入企业详细地址"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="8">
                  <el-form-item label="车辆规模" prop="vehicleScale">
                    <el-select v-model="formData.vehicleScale" placeholder="请选择车辆规模企业全称">
                      <el-option
                          v-for="item in carGmList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="8">
                  <el-form-item label="联系方式"prop="enterprisePhone">
                    <el-input v-model="formData.enterprisePhone" type="text" placeholder="请输入企业联系方式"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="8">
                  <el-form-item label="统一社会信用代码"prop="socialCreditCode">
                    <el-input v-model="formData.socialCreditCode" type="text" placeholder="请输入企业统一信用代码" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="企业类型" prop="enterpriseType">
                    <el-radio-group v-model="formData.enterpriseType">
                      <el-radio label="出租车" v-if="formData.enterpriseType==='出租车'">出租车</el-radio>
                      <el-radio label="租赁公司" v-if="formData.enterpriseType==='租赁公司'">租赁公司</el-radio>
                      <el-radio label="企业招募" v-if="formData.enterpriseType==='企业招募'">企业招募</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>

            </el-form>
          </div>
        </div>
        <div class="loginAction">
          <el-button  class="action" plain size="small">取消</el-button>
          <el-button type="primary " class="action" size="small" @click="submitForm">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgUpload from "@/views/handle/components/ImgUpload";
import {getTree, ocr} from "@/utils/api/tool";
import {updateCompany,getCompanyDetail} from "@/utils/api/company";
import {getStore} from "@/utils/storage";
export default {
  name: "company",
  components:{
    ImgUpload
  },
  data(){
    return{
      labelPosition:"left",
      labelPosition2:"top",
      carGmList: [
        {
          value: '1',
          label: '0-50'
        }, {
          value: '2',
          label: '51-100'
        }, {
          value: '3',
          label: '101-200'
        }, {
          value: '4',
          label: '200以上'
        }
      ], //车辆规模
      cityList: [], //城市三级列表
      scene:0,
      formData:{
        enterpriseName: "", //企业名称
        enterpriseType: 1, //企业类型
        enterpriseLocation: "", //企业所在地
        enterpriseAddress: "", //企业详细地址
        vehicleScale: "", //车辆规模
        enterprisePhone: "", //企业联系方式
        socialCreditCode: "", //社会信用代码

        businessLicenseUrl: '', //营业执照
        proxyStatementUrl: '', //委托书
        transportationLicenseUrl: '', //道路运输许可证url
        taxiOperationLicenseUrl: '', //出租车经营许可证url
        logoUrl: '', //企业logo
        enterprisePhoto:[] , //公司描述图
      },
      ruleCompany:{
        enterpriseName:[
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        enterpriseLocation:[
          { required: true, message: '请选择所在地', trigger: 'blur' },
        ],
        enterpriseType:[
          { required: true, message: '请选择企业类型', trigger: 'blur' },
        ],
        enterpriseAddress:[
          { required: true, message: '请选择所在地址', trigger: 'blur' },
        ],
        vehicleScale:[
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        enterprisePhone:[
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
        socialCreditCode:[
          { required: true, message: '请输入社会信用代码', trigger: 'blur' },
        ],
        businessLicenseUrl:[
          { required: true, message: '请上传营业执照', trigger: 'blur' },
        ],
        proxyStatementUrl:[
          { required: true, message: '请上传营业执照', trigger: 'blur' },
        ],
        transportationLicenseUrl:[
          { required: true, message: '请上传营业执照', trigger: 'blur' },
        ],
        taxiOperationLicenseUrl:[
          { required: true, message: '请上传营业执照', trigger: 'blur' },
        ],
        logoUrl:[
          { required: true, message: '请上传企业Logo', trigger: 'blur' },
        ],
        enterprisePhoto:[
          { required: true, message: '请上传企业Logo', trigger: 'blur' },
        ]
      },
    }
  },
  created() {
    this.getCity()
    this.getCompany()
  },
  methods:{
    getCity(){
      getTree().then(res=>{
        this.cityList= this.utils.fn(res.data.children)
      })
    },
    getCompany(){
      getCompanyDetail().then(res=>{
        if(res.code==0){
          this.formData  = Object.assign({},this.formData, res.data)
        }
        this.formData.enterprisePhoto=this.formData.enterprisePhoto.split(',')
        this.formData.enterprisePhoto=this.formData.enterprisePhoto.filter(function (s) {
          return s && s.trim(); // 注：IE9(不包含IE9)以下的版本没有trim()方法
        });
      })
    },
    submitForm() {
      this.$refs["createForm"].validate((valid) => {
        if (valid) {
          let formData = this.formData
          formData.enterprisePhoto=formData.enterprisePhoto.join(',')
          updateCompany(formData).then(res=>{
            if (res.code==0){
              this.$baseMessage('修改信息成功','success')
              this.getCompany()
            }
          })
        } else {
          this.$baseMessage('表单信息有误','warning')
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["createForm"].resetFields();
    },
    handleChange(value) {
      this.formData.enterpriseLocation= value[2]
    },
    getScene(i){
      this.scene=i
    },
    getImg1(e){
      this.formData.businessLicenseUrl=e
      let baseUrl =getStore('imgBase')
      this.toOcr(baseUrl+e,2)
    },
    getImg2(e){
      this.formData.proxyStatementUrl=e
    },
    getImg3(e){

      this.formData.transportationLicenseUrl=e
    },
    getImg4(e){
      this.formData.taxiOperationLicenseUrl=e

    },
    getImg5(e){
      this.formData.logoUrl=e
    },
    getImg6(e){
      this.formData.enterprisePhoto[0]=e
    },
    getImg7(e){
      this.formData.enterprisePhoto[1]=e
    },
    getImg8(e){
      this.formData.enterprisePhoto[2]=e
    },
    toOcr(img,type){
      let form ={
        cmd:type,
        url:img
      }
      ocr(form).then(res=>{
        let words = JSON.parse(res.data).words_result
        this.ocrText =res.data
        let formData={
          enterpriseName: words['单位名称']['words'], //企业名称
          enterpriseAddress: words['地址']['words'], //企业详细地址
          socialCreditCode: words['社会信用代码']['words'], //社会信用代码
        }
        this.formData = Object.assign({},this.formData, formData)
      })
    }
  }
}
</script>

<style scoped lang="less">
.formCompany{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .formSubmit{
    width: 1540px;
    background: #FFFFFF;
    border-radius: 6px;
    margin: 0 auto;
    padding: 30px;
    .title{
      height: 22px;
      font-size: 16px;
      font-weight: 800;
      color: #333333;
      line-height: 22px;
    }
    .formUpdate{
      .el-form{
        .el-form-item{
          .el-form-item__content{
            font-size: 16px;
            line-height: 42px;
            .el-input__inner{
              height: 42px;
              line-height: 60px;
              background: #FAFAFA;
              border-radius: 2px;
            }
          }
          .img-box{
            width: 150px;
            height: 120px;
            &:hover{
              cursor: pointer;
            }
            img{
              width: 150px;
              height: 120px;
            }
            p{
              width: 150px;
              height: 25px;
              background: rgba(0,0,0,.7);
              border-radius: 0px 0px 2px 2px;
              line-height: 25px;
              text-align: center;
              color: #FFFFFF;
              font-size: 16px;
              position: absolute;
              bottom: 0;
            }
          }
          .el-select{
            width: 100%;

          }
        }
      }
    }
    .loginAction{
      text-align: right;
      border-top: 1px solid #F1F1F1;
      padding-top: 30px;
    }

  }

}

</style>
